import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import HeroSection from "../components/HeroSection";
import Section from "../components/Section";
import stockperksImg from "../images/stockperks.png";



const tableData = [
  {
    title: "What Personal Information We Collect",
    paragraphs: [
      "Information associated with account registration, including name, email address, phone number, and mailing address.",
      "Information submitted as part of contacting us, such as your name and email address.",
      "Information submitted when a user submits user-generated content, such as name and email address.",
      "Information submitted as part of using the Services, including personal financial information (income, expenses, assets, and other financial information), and personal information (date of birth, gender).",
      "Information gathered by cookies (including both session cookies, which are deleted when a user closes the user’s browser, and persistent cookies, which remain after a browser is closed) in accordance with your cookie preferences.",
      "Information gathered by clear gifs and web beacons, including IP address, web browser, device type, pages or sites visited just before or just after using the Services, the pages you view on the Services, your actions on the Services, and the dates and times that you visit, access, or use the Services.",
      "Information from other sources, such as partners and advertisers, as well as application program interface sources at a user’s direction."
    ]
  },
  {
    title: "How We Collect the Personal Information",
    paragraphs: [
      "We ask that users provide this information directly as part of account registration.",
      "Users provide this information directly when contacting us via the Services.",
      "User provide this information directly when submitting user-generated content.",
      "We ask users to provide this information directly in fillable forms as part of using the Services.",
      "We utilize session cookies and persistent cookies in accordance with a user’s expressed cookie preferences.",
      "We utilize clear gifs and web beacons through the Services, which gather information automatically.",
      "We obtain information from partners and advertisers, as well as other third parties that, at a user’s direction, may provide us with information through an application program interface or similar means."
    ]
  },
  {
    title: "Why We Collect the Personal Information",
    paragraphs: [
      "We use this information to set up a user account and verify users on an ongoing basis, as well as to deliver important notices about the Services.",
      "We use this information to answer user inquiries.",
      "We use this information to manage user-generated content on the Services.",
      "We use this information to deliver the Services.",
      "We use this information to improve the delivery of the Services, including allowing a user to avoid entering the same information across multiple uses of the services.",
      "We use this information to understand how users interact with the Services, how users become aware of the Services, and ultimately improve delivery and functionality of the Services.",
      "We use this information to communicate with users and optimize the user experience within the Services."
    ]
  }

]


const PrivacyPolicy = () => {
  require("./privacy.css");
  return (
    <Layout>
      <SEO
        title="Privacy Policy"
        url={"/privacy/"}
        image={stockperksImg}
        description={
          "This Privacy Policy describes the information that we gather from you, how we use and disclose your information, and the steps we take to protect your information."
        }
      />
      <HeroSection>
        <h1>Stockperks, LLC Privacy Policy</h1>
      </HeroSection>
      <Section isTextCentered={false} style={{paddingLeft: 15, paddingRight: 15}}>
        <h4 className="c2" id="h.wsygyeg81cp2">
          <span className="c3">Introduction</span>
        </h4>
        <p className="c5" >
          <span className="c0">
            This Privacy Policy (the &ldquo;Policy&rdquo;) describes the type of information that Stockperks, LLC
            (the &ldquo;Company,&rdquo; &ldquo;we&rdquo; or &ldquo;us&rdquo;) gathers from visitors to the Company website,
            the Company&rsquo;s web-based app, and the Company&rsquo;s mobile app (collectively, the &ldquo;Services&rdquo;).
          </span>
        </p>

        <h4 className="c2" id="h.vwnljrr24muz">
          <span className="c3">Collection, Use, and Sharing of Personal Information</span>
        </h4>

        <p className="c5 c20">
          <span className="c0">
            &ldquo;Personal Information&rdquo; means information that can be associated with a particular user. The chart below details what Personal Information we collect.
          </span>
        </p>

        <div className="container">
          {tableData.map(item => (
              <div className="row" key={item.title} style={{margin:6}}>
                <div className="column">
                  <div className={"title"}>
                    <span className="c6">{item.title}</span>
                  </div>
                  {item.paragraphs.map((paragraph, index) => (
                      <p key={index} ><span className={"numbers"}>{index+1}- </span>
                        {paragraph}</p>
                  ))}
                </div>
              </div>
          ))}
        </div>

        <p className="c5 c20">
          <span className="c0">
            We do not share this information, except as explained below under &ldquo;Third-Party Contractors,&rdquo; &ldquo;Business Transitions,&rdquo; and &ldquo;Compliance with Law and Prevention of Harm.&rdquo;
          </span>
        </p>

        <p className="c5">
         <span className="c6">Third-Party Contractors</span>
          <span className="c0">
           {" "}- We may use contractors (&ldquo;Service Providers&rdquo;) to perform limited services on our behalf, such as hosting websites and providing email services. Service Providers are required to obtain only the Personal Information they need to deliver the service they were hired to perform, to maintain the confidentiality of Personal Information, and not to use Personal Information for any purpose other than the service they were hired to perform.
          </span>
        </p>

        <p className="c5">
          <span className="c6">Business Transitions</span>
          <span className="c0">
            {" "}- We may share information with businesses that are legally part of the same group as the Company, or that become part of that group. We reserve the right – in the event of a business transition such as a merger – to transfer Personal Information to a new business owner, on the condition that such Personal Information must be treated in accordance with this Policy.
          </span>
        </p>

        <p className="c5">
          <span className="c6">Compliance with Law and Prevention of Harm</span>
          <span className="c0">
           {" "}- We may disclose your Personal Information or any information submitted via the Services if we have a good faith belief that disclosure of such information is helpful or reasonably necessary to: (i) comply with any applicable law, regulation, legal process or governmental request; (ii) enforce any applicable terms of service, including investigations of potential violations thereof; (iii) detect, prevent, or otherwise address fraud or security issues; or (iv) protect against harm to the rights, property or safety of the Company, our users, yourself or the public. We may be required to disclose Personal Information in response to a lawful request by public authorities, including to meet national security or law enforcement requirements.
          </span>
        </p>

        <p className="c5 c20">
          <span className="c6">Third Party Content</span>
          <span className="c0">
           {" "}- The Services may include embedded content (e.g., videos, images, articles, etc.) that are linked from other websites. Embedded content is subject to the privacy policies of such websites, rather than this Policy.
          </span>
        </p>

        <p className="c5 c20">
          <span className="c6">No Sale of Personal Information</span>
          <span className="c0">
            {" "}- We do not sell Personal Information.
          </span>
        </p>

        <p className="c5">
          <span className="c6">Use, Sharing, and/or Sale of Non-personal Information</span>
          <span className="c0">
           {" "}- We may aggregate data gathered by the Services. This data does not enable identification of individual users and is not Personal Information as defined by this Policy. As such, our use, sharing, and/or sale of non-personal Information is not restricted by this Policy.
          </span>
        </p>

        <p className="c4">
          <span className="c6">User Choices with Respect to Personal Information</span>
        </p>

        <p className="c5">
          <span className="c0">Users of the Services may request information about our collection, use, and disclosure of their information (a &ldquo;Request to Know&rdquo;), including:
          </span>
        </p>

        <ul>
          <li className="c11">
            <span className="c0">
             the categories and specific pieces of Personal Information we have collected about the user;
            </span>
          </li>
          <li className="c11">
            <span className="c0">
              the business or commercial purpose for collecting or selling the user’s Personal Information;
            </span>
          </li>
          <li className="c11">
            <span className="c0">
              the categories of Personal Information we have sold about the user; and
            </span>
          </li>
          <li className="c11">
            <span className="c0">
              the categories of Third Parties with whom we have shared, disclosed for a business purpose, or sold the user’s Personal Information, and which categories of Personal Information we have sold to which categories of Third Parties.
            </span>
          </li>
        </ul>
        <p className="c5 c20">
          <span className="c0">
           Users may also request that we delete their Personal Information (a “Request to Delete”).
          </span>
        </p>
        <p className="c5 c20">
          <span className="c0 c21">
          Requests to Know
          </span>
        </p>
        <p className="c5 c20">
          <span className="c0">
            Users may submit a Request to Know by completing the form provided in the mobile app or by emailing us at <a href="mailto:privacy@stockperks.com">privacy@stockperks.com</a>.
          </span>
        </p>
        <p className="c5">
          <span className="c0">
            Users may submit two types of Requests to Know: (1) A request for the specific pieces of
            Personal Information that we have collected about you in the past twelve months; or (2) a request
            for the categories of Personal Information that we have collected about you in the past twelve
            months, and we have used and disclosed that Personal Information.
          </span>
        </p>
        <p className="c5">
          <span className="c0">
            When you submit a Request to Know, we may ask you to provide certain pieces of information in order to verify your identity, such as your name, email address, and phone number. If you submit a Request to Know for the specific pieces of information that we have collected about you, we may also require you to submit a signed declaration under the penalty of perjury stating that you are the consumer whose Personal Information is the subject of the Request to Know.
          </span>
        </p>
        <p className="c5">
          <span className="c0">
            If we are able to verify your identity, we will respond to your Request to Know by: (a) providing the requested information; or (b) explaining why we are not required to provide the requested information. If we are unable to verify your identity, we will respond by explaining why we cannot verify your identity. We will confirm receipt of your Request to Know within 10 days and will respond to your Request to Know within 45 days. If a response requires additional time, we will notify you of the basis for the delay and may extend our response period up to an additional 45 days.
          </span>
        </p>
        <p className="c5">
          <span className="c0">
           If we provide the information requested, we will provide the information free of charge and in a readily useable portable format. We have no obligation to provide Personal Information to you more than twice in a 12-month period. If a Request to Know or series of Requests to Know are manifestly unfounded or excessive, we may charge a reasonable fee for processing the Request(s) to Know, or may refuse to process the Request(s) to Know.
          </span>
        </p>

        <p className="c5 c20">
          <span className="c0 c21">
          Requests to Delete
          </span>
        </p>

        <p className="c5">
          <span className="c0">
            Users may submit a Request to Delete by completing the form provided in the mobile app or by emailing us at <a href="mailto:privacy@stockperks.com">privacy@stockperks.com</a>. When you submit a Request to Delete, we may ask you to provide certain pieces of information in order to verify your identity, such as your name, email address, and phone number. If we are able to verify your identity, we will respond to your Request to Delete by (a) deleting your Personal Information and, if applicable, directing any of our Service Providers to delete your Personal Information; or (b) explaining why we are not required to delete your Personal Information. We may choose to delete Personal Information by de-identifying, aggregating, or completely erasing the Personal Information. We will specify the manner in which we delete your Personal Information.
          </span>
        </p>

        <p className="c5">
          <span className="c0">
            If a Request to Delete or series of Requests to Delete are manifestly unfounded or excessive, we may charge a reasonable fee for processing the Request(s) to Delete, or may refuse to process the Request(s) to Delete.
          </span>
        </p>

        <h4 className="c2" >
          <span className="c3">Additional Rights and Information for Users Residing in the European Union, the UnitedKingdom, and Switzerland</span>
        </h4>

        <p className="c5">
          <span className="c0">
            For users outside of the United States, we transfer data from outside countries – including the European Union, the United Kingdom, and Switzerland – to data centers located in the United States for processing. Our processing of Personal Information is intended to be performed in accordance with privacy rights and regulations following the EU Directive 95/46/EC of the European Parliament and of the Council of 24 October 1995 (the Directive), and the implementations of the Directive in local legislation. From May 25, 2018 onward, the Directive and local legislation based on the Directive will be replaced by the Regulations (EU) 2016/679 of the European Parliament and of the Council of April 27, 2016 on the protection of natural persons with regard to the processing of Personal Information and on the free movement of such data, known as the General Data Protection Regulation (“GDPR”). With respect to direct communications with EU residents, we provide the rights required by the GDPR and rely upon such residents’ consent and our legitimate interests in doing business with such residents. With respect to information we may receive from third-parties regarding an EU resident, we rely upon contractual provisions that are in accordance with the GDPR.
          </span>
        </p>

        <p className="c5">
          <span className="c0">
            If you are a resident of the European Union, you have several additional rights under the GDPR regarding Personal Information:
          </span>
        </p>

        <ul>
          <li className="c11">
            <span className="c0">
              You have the right to request an accounting of all Personal Information that we possess that pertains to you in an electronically portable format (e.g., electronic copies of information attached to an email).
            </span>
          </li>
          <li className="c11">
            <span className="c0">
              You have the right to request that we change any Personal Information that pertains to you.
            </span>
          </li>
          <li className="c11">
            <span className="c0">
              You have the right to request that we delete any Personal Information that pertains to you.
            </span>
          </li>
        </ul>

        <p className="c5">
          <span className="c0">
           To request an accounting of your Personal Information, a change to your Personal Information, or deletion of your Personal Information, contact <a href="mailto:privacy@stockperks.com">privacy@stockperks.com</a>.
          </span>
        </p>

        <p className="c5">
          <span className="c0">
            If you have a complaint about our use or processing of your Personal Information, you have the right to lodge a complaint with a national Data Protection Authority. Each European Union member nation has established its own Data Protection Authority; you can find out about the Data Protection Authority in your country <a href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">here</a>.
          </span>
        </p>

        <p className="c4">
          <span className="c6">Protection and Retention of Personal Information</span>
        </p>

        <p className="c5">
          <span className="c0">
            We follow generally accepted industry standards, including the use of appropriate administrative, physical, and technical safeguards, to protect Personal Information. The appropriate administrative, physical, and technical safeguards employed by us may vary depending on the nature of Personal Information collected, with more stringent measures applied to information of a sensitive nature.
          </span>
        </p>
        <p className="c5">
          <span className="c0">
            However, no method of transmission over the Internet, or method of electronic storage, is entirely secure. Therefore, while we strive to use commercially reasonable means to protect Personal Information, we cannot guarantee its absolute security or confidentiality. Please be aware that certain Personal Information and other information provided by you in connection with your use of the Services may be stored on your device (even if that information is not collected by us). You are solely responsible for maintaining the security of your device from unauthorized access.
          </span>
        </p>
        <p className="c5 c20">
          <span className="c0">
            Personal Information will be retained for as long as is reasonably necessary to achieve the purposes set forth in this Policy, and to comply with all applicable laws.
          </span>
        </p>

        <p className="c4">
          <span className="c6">Other Provisions</span>
        </p>
        <p className="c5 c20">
          <span className="c6">Accessibility and Language</span>
          <span className="c0">
            {" "}- Any person that is unable to access this Policy through the Services may request this Policy in an alternative format or language by contacting us at <a href="mailto:privacy@stockperks.com">privacy@stockperks.com</a>.
          </span>
        </p>
        <p className="c5">
          <span className="c6">International Users</span>
          <span className="c0">
            {" "}- The Company and its servers are located in the United States and are subject to applicable local, state, and federal laws. Users who choose to access the Services do so on their own initiative and at their own risk, and are responsible for complying with all applicable laws, rules, and regulations. Users who choose to access the Services consent to the use and disclosure of information in accordance with this Policy and subject to such laws. We may limit the Service’s availability, in whole or in part, to any person, geographic area or jurisdiction we choose, at any time and in our sole discretion. We do not represent or warrant that the Services, or any part thereof, is appropriate or available for use in any other jurisdiction.
          </span>
        </p>
        <p className="c5">
          <span className="c6">Children’s Privacy</span>
          <span className="c0">
            {" "}- The Services are neither directed to nor structured to attract Users under the age of 16. If you are under the age of 16, you are not permitted to use the Services. The Company does not knowingly collect Personal Information from users under the age of 16. If you are a parent with concerns about children’s privacy issues in conjunction with the use of the Services, please contact the Company at <a href="mailto:privacy@stockperks.com">privacy@stockperks.com</a>.
          </span>
        </p>
        <p className="c5">
          <span className="c6">Do Not Track Signals and Collection of Information for Third-Party Advertising</span>
          <span className="c0">
             {" "}- The Company is required to disclose how it responds to “Do Not Track Signals” and whether third parties collect personally identifiable information about users when they use online services. The Company honors &quot;do not track&quot; signals and does not track, use cookies, or use advertising when a “do not track” mechanism is in place. The Company does not authorize the collection of personally identifiable information from our users for third-party use through advertising technologies.
          </span>
        </p>
        <p className="c5">
          <span className="c6">Amendments</span>
          <span className="c0">
            {" "}- We may modify or amend this Privacy Policy from time to time. If we make any material changes, as determined by us, to this Privacy Policy, including in the way in which Personal Information is collected, used, or transferred, we will notify you by e-mail to the address specified in your profile or by means of a notice on the Services prior to the change becoming effective.
          </span>
        </p>

        <p className="c5">
          <span className="c6">Contact Information</span>
        </p>
        <p className="c5">
          <span className="c0">If you have questions about this Policy, please contact <a href="mailto:privacy@stockperks.com">privacy@stockperks.com</a>.</span>
        </p>

        <p className="c5">
          <span className="c6">Effective Date</span>
        </p>
        <p className="c5">
          <span className="c0">The effective date of this Policy is October 1, 2023.</span>
        </p>
      </Section>
    </Layout>
  );
};



export default PrivacyPolicy;


